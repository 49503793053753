<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("ref_system.name") }} 3%</h1>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="row">
      <div class="col-12 col-xl-5">
        <div class="statistic _ref">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-xl-5">
              <div class="statistic__item">
                <div class="statistic__icon align-center">
                  <svg v-svg role="presentation" size="0 0 16 17" symbol="user" />
                </div>
                <div class="statistic__label">
                  {{ $t("ref_system.referals") }}
                </div>
                <div class="statistic__value">$ {{ totalReferrals }}</div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <div class="statistic__item">
                <div class="statistic__icon align-center">
                  <svg v-svg role="presentation" size="0 0 17 17" symbol="menu-6" />
                </div>
                <div class="statistic__label">
                  {{ $t("ref_system.income") }}
                </div>
                <div class="statistic__value">$ {{ totalIncome }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-5 ml-auto">
        <div class="pl-xl-5 fs-13">
          <h4>{{ $t("ref_system.ref_link") }}</h4>
          <p>{{ $t("ref_system.use_ref_partners") }}</p>
          <div class="form-item pb-0">
            <div class="form-item__field _copy">
              <input id="ref-link" :value="refUrl" readonly type="text" />
              <button class="btn icon-copy" type="button" @click="copyRefLink">
                <svg v-svg role="presentation" size="0 0 12 14" symbol="copy" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sep _h10"></div>
    <div class="c-table mt-5 mt-md-0" v-if="refs.length">
      <div class="inner">
        <table class="table _no-wrap sortEnable tablesorter tablesorter-bootstrap" role="grid">
          <thead>
            <tr>
              <th>UID</th>
              <th class="w-100">{{ $t("ref_system.register_date") }}</th>
              <th>{{ $t("ref_system.today") }}</th>
              <th>{{ $t("ref_system.yesterday") }}</th>
              <th>{{ $t("ref_system.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in refs" :key="index">
              <td>{{ item.uid }}</td>
              <td>{{ item.created_at }}</td>
              <td class="bg-gray">
                <span class="font-weight-bold">$ {{ item.today_profit }} </span>
              </td>
              <td class="bg-gray">
                <span class="font-weight-bold">$ {{ item.yesterday_profit }} </span>
              </td>
              <td class="bg-green">
                <span class="font-weight-bold">$ {{ item.total_profit }} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="no-information">
      <span class="icon-user-empty"></span>
      <h3>{{$t('ref_system.no_refs')}}</h3>
      <p>{{$t('ref_system.share_ref')}} </p>
    </div>
    <notifications group="foo" />
  </div>
</template>

<style lang="scss" scoped>
.statistic {
  &._ref {
    background: #245e5b;
    width: 100%;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 15px;
    padding: 14px 20px;
  }
  &__item {
    padding: 10px 0 10px 62px;
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 47px;
    height: 47px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    color: #e1e2ec;
  }
  &__label {
    color: #9dbebe;
  }
  &__value {
    padding-top: 4px;
    font-weight: bold;
    font-size: 18px;
  }
}
.no-information {
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    color: #717171;
    padding: 100px 0;
  span {
    font-size: 66px;
    color: #DDDDDD;
    display: block;
    margin-bottom: 40px;
  }
  h3 {
    color: #000;
  }
}
</style>

<script>
import PanelService from "@/api/servicePanel";
export default {
  name: "RefSystem",
  beforeMount() {
    document.title = `Revopush | ${this.$t("ref_system.name")}`;
  },
  beforeUpdate() {
    document.title = `Revopush | ${this.$t("ref_system.name")}`;
  },
  mounted() {
    PanelService.getReferals().then((res) => {
      if (res && res.status === 200) {
        this.refUrl = res.data.ref_url;
        this.refs = res.data.referrals;
        this.totalIncome = res.data.total_income;
        this.totalReferrals = res.data.total_referrals;
      }
    });
  },
  data() {
    return {
      refs: [],
      refUrl: "",
      totalIncome: 0,
      totalReferrals: 0,
    };
  },
  methods: {
    copyRefLink() {
      let link = document.querySelector("#ref-link").value;
      navigator.clipboard.writeText(link);
      this.$notify({
        group: "foo",
        title: this.$t("ref_system.copied"),
      });
    },
  },
};
</script>